import { createRouter , createWebHashHistory } from 'vue-router'
import routes from './route.js';
import apiStaff from '@/api/staff';
import store from '@/store';
const layout = () => import('@/layout/index.vue');

const router = createRouter({
   history : createWebHashHistory(),
   routes
});

const initRoutes = async (routeList) => {
    const menuList = routeList.filter(item => item.type === 2);
    return new Promise((resolve) => {
        for (let i = 0; i < menuList.length; i++) {
            const item = menuList[i];
            // console.log(item.name);
            const pathList = item.path.split('/');
            const routeInfo = {
                path: '/' + pathList[1],
                name: pathList[1],
                component: layout,
                children: [{
                    name: item.permission,
                    meta: {
                        title: item.name,
                        iconClassName: item.icon,
                    },
                    path: pathList[2],
                    component: () => import(`@/views${item.component}.vue`)
                }]
            };
            console.log(routeInfo);
            router.addRoute(routeInfo);
        }
        resolve();
    })
}

router.beforeEach(async (to, from, next) => {
    console.log('from route:', from);
    console.log('to route:', to);
    if (['login', 'reg'].includes(to.name)) {
        return next();
    }
    console.log(store);
    console.log(window.myInfo);
    if (!window.myInfo) {
        await apiStaff.getMyInfo().then(res => {
            console.log(res);
            if (res.code === 100) {
                window.myInfo = res.content;
            }
        }).catch(err => {
            console.log('err', err);
            next({
                name: 'login'
            })
        });
    }
    if (!window.navList) {
        await apiStaff.getMyMenu().then(res => {
            console.log(res);
            if (res.code === 100) {
                window.navList = res.content;
                initRoutes(res.content);
                console.log('init finish');
            }
        })
        return next({ ...to, replace: true });
    }
    console.log('init matched');
    if (!to.matched.length) {
        return next({name: '404'});
    } else if (!localStorage.getItem('UserLoginToken') && to.name !== 'login') {
        return next({name: 'login'});
    }
    next()
  })

export default router