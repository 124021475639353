export const transformTozTreeFormat = (setting, sNodes) => {
    let i, l;
    const key = setting.idKey;
    const parentKey = setting.pIdKey;
    const childKey = setting.childrenKey;
    if (!key || key === '' || !sNodes) return [];
    function isArray(arr) {
        return Object.prototype.toString.apply(arr) === '[object Array]';
    }

    if (isArray(sNodes)) {
        const r = [];
        const tmpMap = {};
        for (i = 0, l = sNodes.length; i < l; i++) {
            tmpMap[sNodes[i][key]] = sNodes[i];
        }
        for (i = 0, l = sNodes.length; i < l; i++) {
            if (tmpMap[sNodes[i][parentKey]] && sNodes[i][key] !== sNodes[i][parentKey]) {
                if (!tmpMap[sNodes[i][parentKey]][childKey]) { tmpMap[sNodes[i][parentKey]][childKey] = []; }
                tmpMap[sNodes[i][parentKey]][childKey].push(sNodes[i]);
            } else {
                r.push(sNodes[i]);
            }
        }
        return r;
    } else {
        return [sNodes];
    }
}