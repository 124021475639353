const layout = () => import('@/layout/index.vue');
const routes = [
    {
        path: '/',
        name: 'empty',
        redirect: { name: 'index' }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
            iconClassName: 'Notebook',
        },
        component: () => import(/* webpackChunkName: "login" */'../views/login.vue')
    },
    {
        path: '/reg',
        name: 'reg',
        meta: {
            title: '注册',
            iconClassName: 'Notebook',
        },
        component: () => import(/* webpackChunkName: "login" */'../views/reg.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '找不到页面',
            iconClassName: 'Notebook',
        },
        component: () => import(/* webpackChunkName: "404" */'../views/404.vue')
    },
    {
        path: '/403',
        name: '403',
        meta: {
            title: '找不到页面',
            iconClassName: 'Notebook',
        },
        component: () => import(/* webpackChunkName: "403" */'../views/403.vue')
    },

    {
        path: '/index',
        component: layout,
        children: [{
            name: 'index',
            meta: {
                title: '首页',
                iconClassName: 'Notebook',
            },
            path: '/index',
            component: () => import(/* webpackChunkName: "productIndex" */'@/views/index.vue')
        }]
    },
    // {
    //     path: '/staff',
    //     component: layout,
    //     children: [{
    //         name: 'staffIndex',
    //         meta: {
    //             title: '用户',
    //             iconClassName: 'User',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "stevedoresIndex" */'@/views/staff/index.vue')
    //     }]
    // },
    // {
    //     path: '/role',
    //     component: layout,
    //     children: [{
    //         name: 'roleIndex',
    //         meta: {
    //             title: '角色',
    //             iconClassName: 'User',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "stevedoresIndex" */'@/views/role/index.vue')
    //     }]
    // },
    // {
    //     path: '/permission',
    //     component: layout,
    //     children: [{
    //         name: 'permissionIndex',
    //         meta: {
    //             title: '权限',
    //             iconClassName: 'User',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "stevedoresIndex" */'@/views/permission/index.vue')
    //     }]
    // },
    // {
    //     path: '/operationlog',
    //     name: 'operationlog',
    //     meta: {
    //         title: '请求日志'
    //     },
    //     component: layout,
    //     children: [{
    //         name: 'operationLogIndex',
    //         meta: {
    //             title: '操作日志',
    //             iconClassName: 'DocumentCopy',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "operationLogIndex" */'@/views/operationLog/index.vue')
    //     }]
    // },
    // {
    //     path: '/erpnotifylog',
    //     name: 'erpnotifylog',
    //     meta: {
    //         title: '请求日志'
    //     },
    //     component: layout,
    //     children: [{
    //         name: 'ERPNotifyLogIndex',
    //         meta: {
    //             title: '推送日志',
    //             iconClassName: 'DocumentCopy',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "ERPNotifyLogIndex" */'@/views/erpnotifylog/index.vue')
    //     }]
    // },
    // {
    //     path: '/globalconfig',
    //     component: layout,
    //     children: [{
    //         name: 'globalconfigIndex',
    //         meta: {
    //             title: '全局配置',
    //             iconClassName: 'Notebook',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "globalconfigIndex" */'@/views/globalconfig/index.vue')
    //     }]
    // },

    // {
    //     path: '/material',
    //     name: 'material',
    //     meta: {
    //         title: '物料管理'
    //     },
    //     component: layout,
    //     children: [{
    //         name: 'materialIndex',
    //         meta: {
    //             title: '物料库存列表',
    //             iconClassName: 'Goods',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "materialIndex" */'@/views/material/index.vue')
    //     }]
    // },
    // {
    //     path: '/materialbasicinfo',
    //     name: 'materialbasicinfo',
    //     meta: {
    //         title: '物料管理'
    //     },
    //     component: layout,
    //     children: [{
    //         name: 'materialBasicInfoIndex',
    //         meta: {
    //             title: '物料信息',
    //             iconClassName: 'Goods',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "materialBasicInfoIndex" */'@/views/materialbasicinfo/index.vue')
    //     }]
    // },
    // {
    //     path: '/materialrecord',
    //     name: 'materialrecord',
    //     meta: {
    //         title: '物料管理'
    //     },
    //     component: layout,
    //     children: [{
    //         name: 'materialRecordIndex',
    //         meta: {
    //             title: '物料变动记录',
    //             iconClassName: 'Goods',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "materialRecordIndex" */'@/views/materialrecord/index.vue')
    //     }]
    // },
    // {
    //     path: '/equipment',
    //     name: 'equipment',
    //     meta: {
    //         title: '物料管理'
    //     },
    //     component: layout,
    //     children: [{
    //         name: 'equipmentIndex',
    //         meta: {
    //             title: '设备列表',
    //             iconClassName: 'Goods',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "equipmentIndex" */'@/views/equipment/index.vue')
    //     }]
    // },
    // {
    //     path: '/instocknote',
    //     component: layout,
    //     children: [{
    //         name: 'inStockNoteIndex',
    //         meta: {
    //             title: '入库单',
    //             iconClassName: 'Timer',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "inStockNoteIndex" */'@/views/instocknote/index.vue')
    //     }]
    // },
    // {
    //     path: '/outstocknote',
    //     component: layout,
    //     children: [{
    //         name: 'outStockNoteIndex',
    //         meta: {
    //             title: '出库单',
    //             iconClassName: 'DocumentChecked',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "outStockNoteIndex" */'@/views/outstocknote/index.vue')
    //     }]
    // },
    // {
    //     path: '/checkstocknote',
    //     component: layout,
    //     children: [{
    //         name: 'checkStockNoteIndex',
    //         meta: {
    //             title: '盘点单',
    //             iconClassName: 'CircleCheck',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "checkStockNoteIndex" */'@/views/checkstocknote/index.vue')
    //     }]
    // },
    // {
    //     path: '/refundstocknote',
    //     component: layout,
    //     children: [{
    //         name: 'refundStockNoteIndex',
    //         meta: {
    //             title: '退料单',
    //             iconClassName: 'Wallet',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "refundStockNoteIndex" */'@/views/refundstocknote/index.vue')
    //     }]
    // },
    // {
    //     path: '/movestocknote',
    //     component: layout,
    //     children: [{
    //         name: 'moveStockNoteIndex',
    //         meta: {
    //             title: '移库单',
    //             iconClassName: 'Money',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "refundStockNoteIndex" */'@/views/movestocknote/index.vue')
    //     }]
    // },
    // {
    //     path: '/movestockrecord',
    //     component: layout,
    //     children: [{
    //         name: 'moveStockRecordIndex',
    //         meta: {
    //             title: '移库记录',
    //             iconClassName: 'Money',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "moveStockRecordIndex" */'@/views/movestockrecord/index.vue')
    //     }]
    // },
    // {
    //     path: '/position',
    //     component: layout,
    //     children: [{
    //         name: 'positionIndex',
    //         meta: {
    //             title: '库位列表',
    //             iconClassName: 'Goods',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "positionIndex" */'@/views/position/index.vue')
    //     }]
    // },
    // {
    //     path: '/materialunitrelationship',
    //     component: layout,
    //     children: [{
    //         name: 'materialUnitRelationshipIndex',
    //         meta: {
    //             title: '单位转换关系',
    //             iconClassName: 'Goods',
    //         },
    //         path: 'index',
    //         component: () => import(/* webpackChunkName: "materialUnitRelationshipIndex" */'@/views/materialunitrelationship/index.vue')
    //     }]
    // }

]

export default routes;