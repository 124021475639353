import { createApp } from "vue";
var dayjs = require('dayjs');
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/layout/export'
import {transformTozTreeFormat} from '@/util/util';
import installElementPlus from "./plugins/element";
require('./util/drag.js')
const app = createApp(App);
installElementPlus(app);
app.config.globalProperties.$filters = {
    formatTime(time, type) {
        if (!time) {
            return '';
        }
        if (type === 'date') {
            return dayjs(time).format('YYYY-MM-DD');
        } else if (type === 'time') {
            return dayjs(time).format('HH:mm:ss');
        }
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    }
}
app.config.globalProperties.$transformTozTreeFormat = transformTozTreeFormat;

app.config.globalProperties.$baseURL = window.vars.baseURL;
app.config.globalProperties.$pageSizeList = [10, 20, 50, 100];
app.config.globalProperties.$pagerLayout = "total, sizes, slot, prev, pager, next";

app.use(store).use(router).mount("#app");
